import { Link } from "react-router-dom";
import style from "./NotificationPopup.module.scss";
import { NotificationTypeEnum } from "../../../../app/types";
import dayjs from "dayjs";
import { getNotificationText } from "../../../../utils/getNotificationText";
import AntPopConfirm from "../../../../components/AntPopConfirm";
import GreenButton from "../../../../components/GreenButton";
import { useAppDispatch } from "../../../../app/hooks";
import {
  openApprovePhotoModal,
  setApproveFromUserPage,
  setPersonIdThatNeedApprovePhoto,
} from "../../../../storeSlices/approvePhotoData";
import getSubStringByLength from "../../../../utils/getSubStringByLength";
import { usePutApiNotificationsReadByIdMutation } from "../../../../app/apiRTK";
import { message } from "antd";
import createConfig from "../../../../components/AntMessageConfig";
import { PopupType } from "./types";

const NotificationPopup = (props: PopupType) => {
  const {
    setIsNotificationPopup,
    notifications = [],
    readAllMessages,
    notificationsRefetch,
  } = props;

  const dispatch = useAppDispatch();

  const [readNotificationByApi] = usePutApiNotificationsReadByIdMutation();

  const [messageApi, contextHolder] = message.useMessage();

  const readNotification = (id: number) => {
    readNotificationByApi({ id })
      .unwrap()
      .then(() => {
        notificationsRefetch()
          .unwrap()
          .catch(() => {
            messageApi.open(
              createConfig({
                type: "error",
                content: "Ошибка! Не удалось обновить уведомления",
              })
            );
          });
      })
      .catch(() => {
        messageApi.open(
          createConfig({
            type: "error",
            content: "Ошибка! Не удалось прочитать уведомление",
          })
        );
      });
  };

  return (
    <>
      {contextHolder}
      <div className={style.popup}>
        <div className={style.header}>
          <h3>
            {getNotificationText(notifications?.length)}{" "}
            {notifications?.length > 0 && (
              <AntPopConfirm
                title="Отметить все уведомления как прочитанные"
                description={
                  "Вы уверены, что хотите отметить все уведомления как прочитанные?\nЭто действие нельзя будет отменить."
                }
                onConfirm={readAllMessages}
                okText="Прочитать"
                cancelText="Отменить"
              >
                <span className={style.link}>Прочитать все</span>
              </AntPopConfirm>
            )}
          </h3>
        </div>
        <div className={style.content}>
          {notifications.length > 0 &&
            notifications
              .sort((a, b) => {
                return dayjs(a.time).isBefore(dayjs(b.time)) ? 1 : -1;
              })
              .slice(0, 5)
              .map((nf, i) => {
                return (
                  <div className={style.notification} key={i}>
                    <div className={style.title}>{nf.title}</div>
                    <div className={style.text}>
                      {getSubStringByLength(nf.description, 110)}
                    </div>

                    {nf.type === NotificationTypeEnum["Запрос на фото"] ? (
                      <>
                        {!!nf.personId && (
                          <GreenButton
                            text="Посмотреть"
                            type="normal"
                            onClick={() => {
                              readNotification(nf.id);

                              setIsNotificationPopup(false);
                              dispatch(
                                setPersonIdThatNeedApprovePhoto(
                                  nf.personId as number
                                )
                              );
                              dispatch(setApproveFromUserPage(false));
                              dispatch(openApprovePhotoModal(true));
                            }}
                          />
                        )}
                      </>
                    ) : nf.type === NotificationTypeEnum["Новый осмотр"] ? (
                      <Link
                        to={`/exams/${nf.examId}`}
                        onClick={() => {
                          readNotification(nf.id);
                          setIsNotificationPopup(false);
                        }}
                        className={style.link}
                      >
                        Перейти
                      </Link>
                    ) : null}

                    <div className={style.date}>{nf.time}</div>
                  </div>
                );
              })}
        </div>
        <div className={style.footer}>
          <div className={style.allNotification}>
            <Link
              to="notifications"
              onClick={() => setIsNotificationPopup(false)}
              className={style.link}
            >
              Все уведомления
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPopup;
