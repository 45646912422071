import { Skeleton } from "antd";
import style from "./AntSkeleton.module.scss";
import { SkeletonProps } from "antd/lib";

const AntSkeleton = (props: SkeletonProps) => {
  return (
    <Skeleton
      round={true}
      active={true}
      className={style.skeleton}
      {...props}
    />
  );
};

export default AntSkeleton;
