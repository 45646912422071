import { Image, ImageProps } from "antd";
import style from "./AntImage.module.scss";
import { AntImagePreviewGroupProps } from "./types";

const AntImage = (props: ImageProps) => {
  return (
    <Image
      {...props}
      className={style.image}
      rootClassName={style.imageWrapper}
    />
  );
};

const PreviewGroup = ({
  groupImages,
  preview,
  ...props
}: AntImagePreviewGroupProps) => {
  return (
    <Image.PreviewGroup
      preview={Object.assign(
        {},
        {
          rootClassName: style.imageWrapper,
        },
        preview
      )}
    >
      {groupImages
        ? groupImages.map((src, index) => (
            <AntImage key={index} {...Object.assign({}, props, { src: src })} />
          ))
        : props.children}
    </Image.PreviewGroup>
  );
};

AntImage.PreviewGroup = PreviewGroup;

export default AntImage;
