import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, message, notification } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import find from "lodash/find";
import {
  ArrowLeftIcon,
  AvatarIcon,
  BellHasNotificationIcon,
  BellIcon,
  CalendarIcon,
} from "../../assets/icons";
import AntPopover from "../../components/AntPopover";
import NotificationPopup from "./components/NotificationPopup";
import SupportPopup from "./components/SupportPopup";
import ProfilePopup from "./components/ProfilePopup";
import style from "./Root.module.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LeftSideBar from "./components/LeftSideBar";
import { getTitleFromPath } from "../../utils/pathMapFuncs";
import Preloader from "../../components/AntPreloader";
import {
  appPreloaderStatusState,
  setAppError,
} from "../../storeSlices/appSettings";
import {
  isOpenApprovePhotoModalState,
  personIdThatNeedApprovePhotoState,
} from "../../storeSlices/approvePhotoData";
import {
  authPersonIdState,
  authPersonState,
  authRoleState,
} from "../../storeSlices/login";
import {
  useGetApiNotificationsQuery,
  useLazyGetApiFilesDownloadFileByFileNameQuery,
  useLazyGetApiPersonsByIdQuery,
  usePutApiNotificationsReadAllByPersonIdMutation,
  usePutApiPersonsChangeNotificationTokenMutation,
} from "../../app/apiRTK";
import AntAvatar from "../../components/AntAvatar";
import { PhoneIcon } from "../../assets/icons/Phone";
import { getFirebaseToken, onForegroundMessage } from "../../app/firebase";
import createConfig from "../../components/AntMessageConfig";
import { MessagePayload } from "firebase/messaging";
import { MessageNotificationIcon } from "../../assets/icons/MessageNotification";
import { skipToken } from "@reduxjs/toolkit/query";
import { EnumRoles, INotification, TypeNewExam } from "../../app/types";
import { setHasNewNotification } from "../../storeSlices/notification";
import ApprovePhotoModal from "./components/ApprovePhotoModal";

const reloadCounter = localStorage.getItem("reloadCounter") || 0;

if (reloadCounter) {
  localStorage.setItem("reloadCounter", reloadCounter);
} else {
  localStorage.setItem("reloadCounter", JSON.stringify(reloadCounter));
}

function Root() {
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [isNotificationPopup, setIsNotificationPopup] = useState(false);
  const [isDatePicker, setIsDatePicker] = useState(false);

  const preloaderStatus = useAppSelector(appPreloaderStatusState);
  const isOpenApprovePhotoModal = useAppSelector(isOpenApprovePhotoModalState);
  const personIdThatNeedApprovePhoto = useAppSelector(
    personIdThatNeedApprovePhotoState
  );
  const authPerson = useAppSelector(authPersonState);
  const authPersonId = useAppSelector(authPersonIdState);
  const authRole = useAppSelector(authRoleState);

  const [messageApi, contextHolder] = message.useMessage();
  const [api, contextHolderNotification] = notification.useNotification();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { examId, personId } = useParams();

  // Название страницы в шапке. Может быть с кнопкой Назад
  let pageTitle = getTitleFromPath(location.pathname) || "Главная";

  // useGet
  const {
    data: notifications,
    isError: isNotificationError,
    refetch: notificationsRefetch,
  } = useGetApiNotificationsQuery(
    authPersonId && typeof authPersonId === "number"
      ? { personId: authPersonId }
      : skipToken
  );

  // useGetLazy
  const [getPhotoUrl, { data: photoUrl }] =
    useLazyGetApiFilesDownloadFileByFileNameQuery();
  const [getAuthPerson] = useLazyGetApiPersonsByIdQuery();

  // useMutation
  const [changePersonToken] = usePutApiPersonsChangeNotificationTokenMutation();
  const [putApiNotificationsReadAllByPersonIdMutation] =
    usePutApiNotificationsReadAllByPersonIdMutation();

  const newMessage = useCallback(
    (data: MessagePayload) => {
      const msg = data.data?.ExamRelatedNotificationMessage;
      if (msg) {
        const parsedMsg = JSON.parse(msg) as TypeNewExam;

        api.info({
          message: parsedMsg.Title,
          description: parsedMsg.Body,
          duration: 10,
          placement: "bottomRight",
          icon: <MessageNotificationIcon />,
        });

        // Запрашиваем уведомления с бэка повторно, т.к. пришло с файрбеза
        notificationsRefetch();
        // Меняем в Redux флаг, что уведомление пришло
        dispatch(setHasNewNotification(true));
      }
    },
    [api, notificationsRefetch, dispatch]
  );

  useEffect(() => {
    if (isNotificationError) {
      messageApi.open(
        createConfig({
          type: "error",
          content: "Возникла ошибка при загрузке уведомлений",
        })
      );
    }
  }, [isNotificationError, messageApi]);

  useEffect(() => {
    onForegroundMessage(newMessage);
  }, [newMessage]);

  useEffect(() => {
    if (
      authPerson &&
      authPerson.personPhotos &&
      authPerson.personPhotos.length > 0
    ) {
      const actualPhoto = find(authPerson.personPhotos, { status: 1 });
      if (actualPhoto) {
        getPhotoUrl({ fileName: actualPhoto.fileName });
      }
    }
  }, [authPerson, getPhotoUrl]);

  useEffect(() => {
    if (authPerson) {
      Notification.requestPermission().then(function (result) {
        if (result === "denied" || result === "default") {
          messageApi.open(
            createConfig({
              type: "error",
              content: "Ошибка! Включите разрешение для уведомлений",
            })
          );
        } else {
          getFirebaseToken()
            .then((firebaseToken) => {
              if (firebaseToken) {
                if (authPerson.notificationToken) {
                  if (authPerson.notificationToken !== firebaseToken) {
                    changePersonToken({
                      id: authPerson.id,
                      notificationToken: firebaseToken,
                    })
                      .unwrap()
                      .then(() => {
                        getAuthPerson({ id: authPerson.id });
                      });
                  }
                } else {
                  changePersonToken({
                    id: authPerson.id,
                    notificationToken: firebaseToken,
                  })
                    .unwrap()
                    .then(() => {
                      getAuthPerson({ id: authPerson.id });
                    });
                }
              } else {
                console.error("No Firebase token!");
              }
            })
            .catch((err) => {
              console.error(
                "An error occured while retrieving firebase token. ",
                err
              );

              if (+reloadCounter < 3) {
                localStorage.setItem(
                  "reloadCounter",
                  JSON.stringify(+reloadCounter + 1)
                );
                navigate(0);
              }
            });
        }
      });
    }
  }, [
    authPerson,
    getPhotoUrl,
    changePersonToken,
    getAuthPerson,
    messageApi,
    navigate,
  ]);

  useEffect(() => {
    window.addEventListener("error", (event) => {
      dispatch(setAppError(event.message));
    });

    window.addEventListener(
      "unhandledrejection",
      (event: PromiseRejectionEvent) => {
        dispatch(setAppError(event.reason.message));
      }
    );
  }, [dispatch]);

  // Кнопка Назад на конкретном осмотре
  if ((examId || personId) && window.history.length > 1) {
    pageTitle = (
      <>
        <button onClick={() => navigate(-1)} className={style.backBtn}>
          <ArrowLeftIcon />
        </button>
        {pageTitle}
      </>
    );
  }

  const readAllNotifications = () => {
    if (authPersonId && typeof authPersonId === "number") {
      putApiNotificationsReadAllByPersonIdMutation({ personId: authPersonId })
        .unwrap()
        .then(() => {
          notificationsRefetch();
        });
    }
  };

  let newMessages: INotification[] | [] = [];
  if (notifications && notifications.length > 0) {
    newMessages = notifications
      .filter((msg) => !msg.isRead)
      .map((msg) => {
        const parsedMsg = JSON.parse(msg.messageJson) as TypeNewExam;
        return {
          id: msg.id,
          title: parsedMsg.Title,
          description: parsedMsg.Body,
          examId: parsedMsg.ExamId,
          personId: parsedMsg.PersonId,
          time: dayjs(msg.creationDate).format("HH:mm"),
          type: msg.type,
        } as INotification;
      });
  }

  return (
    <>
      {contextHolder}
      {contextHolderNotification}
      <Preloader spinning={preloaderStatus} size="large">
        <div className={style.dashboard}>
          <div className={style.topNav}>
            <div className={style.pageTitle}>
              <h1>{pageTitle}</h1>
            </div>
            <div className={style.info}>
              <div className={style.calendar}>
                <CalendarIcon />
                <DatePicker
                  value={dayjs(dayjs(), "DD/MM/YYYY")}
                  format={"DD/MM/YYYY"}
                  className={style.datePicker}
                  popupClassName={style.popupDatePicker}
                  inputReadOnly={true}
                  allowClear={false}
                  bordered={false}
                  suffixIcon={false}
                  locale={locale}
                  showToday={false}
                  open={isDatePicker}
                  onOpenChange={(visible) => {
                    setIsDatePicker(visible);
                  }}
                />
              </div>
              <div className={style.support}>
                <AntPopover
                  content={<SupportPopup />}
                  trigger="click"
                  placement="rightTop"
                >
                  <button type="button">
                    <PhoneIcon />
                  </button>
                </AntPopover>
              </div>
              <div className={style.notification}>
                <AntPopover
                  content={
                    <NotificationPopup
                      setIsNotificationPopup={setIsNotificationPopup}
                      notifications={newMessages}
                      readAllMessages={readAllNotifications}
                      notificationsRefetch={notificationsRefetch}
                    />
                  }
                  trigger="click"
                  placement="rightTop"
                  onOpenChange={(visible) => setIsNotificationPopup(visible)}
                  open={isNotificationPopup}
                >
                  <button type="button">
                    {newMessages.length ? (
                      <BellHasNotificationIcon />
                    ) : (
                      <BellIcon />
                    )}
                  </button>
                </AntPopover>
              </div>
              <div className={style.profile}>
                <AntPopover
                  content={
                    <ProfilePopup setIsProfilePopup={setIsProfilePopup} />
                  }
                  trigger="click"
                  placement="rightTop"
                  onOpenChange={(visible) => setIsProfilePopup(visible)}
                  open={isProfilePopup}
                >
                  <button type="button">
                    <AntAvatar
                      size={38}
                      icon={<AvatarIcon />}
                      src={photoUrl?.url ? photoUrl.url : undefined}
                    />
                  </button>
                </AntPopover>
              </div>
            </div>
          </div>
          <div className={style.mainPage}>
            <Outlet />
          </div>
        </div>

        <LeftSideBar />
        {/* Делаю проверку тут чтобы модалка не рендерилась раньше времени и дестроилась после закрытия */}
        {isOpenApprovePhotoModal &&
          !!personIdThatNeedApprovePhoto &&
          authRole?.includes(EnumRoles.Руководитель) && (
            <ApprovePhotoModal
              personId={personIdThatNeedApprovePhoto}
              messageApi={messageApi}
              notificationsRefetch={notificationsRefetch}
            />
          )}
      </Preloader>
    </>
  );
}

export default Root;
