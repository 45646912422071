import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import AntModal from "../../../../components/AntModal";

import {
  isApproveFromUserPageState,
  openApprovePhotoModal,
  setIsNeedRefetchUserPage,
  setPersonIdThatNeedApprovePhoto,
} from "../../../../storeSlices/approvePhotoData";
import style from "./ApprovePhotoModal.module.scss";
import { IProps } from "./types";
import {
  useGetApiPersonsByIdQuery,
  useLazyGetApiFilesDownloadFileByFileNameQuery,
  useLazyGetApiPersonPhotosGetByPersonIdByPersonIdQuery,
  usePutApiPersonPhotosApproveRequestMutation,
  usePutApiPersonPhotosDeclineRequestMutation,
} from "../../../../app/apiRTK";
import { find } from "lodash";
import AntSkeleton from "../../../../components/AntSkeleton";
import AntImage from "../../../../components/AntImage";
import AntButtonGreen from "../../../../components/AntButtonGreen";
import { CheckIcon, XCloseIcon } from "../../../../assets/icons";
import createConfig from "../../../../components/AntMessageConfig";
import { getFioWithDots, normalizePhone } from "../../../../app/helpers";
import dayjs from "dayjs";

const ApprovePhotoModal = (props: IProps) => {
  const { personId, messageApi, notificationsRefetch } = props;

  // useGet
  const { data: person } = useGetApiPersonsByIdQuery({ id: personId });

  // useLazy
  const [getPersonPhotos] =
    useLazyGetApiPersonPhotosGetByPersonIdByPersonIdQuery();
  const [downloadPhoto, { data: personPhotoUrl }] =
    useLazyGetApiFilesDownloadFileByFileNameQuery();

  // useMutation
  const [approvePhoto, { isLoading: isApproveLoading }] =
    usePutApiPersonPhotosApproveRequestMutation();
  const [declinePhoto, { isLoading: isDeclineLoading }] =
    usePutApiPersonPhotosDeclineRequestMutation();

  const dispatch = useAppDispatch();

  const isApproveFromUserPage = useAppSelector(isApproveFromUserPageState);

  useEffect(() => {
    getPersonPhotos({ personId })
      .unwrap()
      .then((personPhotos) => {
        const needApprovePhoto = find(personPhotos, { status: 0 });

        if (needApprovePhoto) {
          downloadPhoto({ fileName: needApprovePhoto.fileName });
        }
      });
  }, [getPersonPhotos, personId, downloadPhoto]);

  if (!personPhotoUrl || !personPhotoUrl.url || !person) {
    return <AntSkeleton />;
  }

  return (
    <AntModal
      title="Запрос на загрузку фото в профиль:"
      open={true}
      onCancel={() => {
        dispatch(openApprovePhotoModal(false));
      }}
      className={style.modal}
      footer={[
        <AntButtonGreen
          type="primary"
          key="cancel"
          onClick={() => {
            declinePhoto({ personId })
              .unwrap()
              .then(() => {
                notificationsRefetch()
                  .unwrap()
                  .then(() => {
                    dispatch(openApprovePhotoModal(false));
                    dispatch(setPersonIdThatNeedApprovePhoto(null));

                    // Проверяем если модалку вызвали со страницы редактирования пользователя
                    if (isApproveFromUserPage) {
                      dispatch(setIsNeedRefetchUserPage(true));
                    }

                    messageApi.open(
                      createConfig({
                        type: "success",
                        content: "Фото успешно отклонено!",
                      })
                    );
                  });
              })
              .catch(() => {
                messageApi.open(
                  createConfig({
                    type: "error",
                    content: "Возникла ошибка при отклонении фото",
                  })
                );
              });
          }}
          icon={<XCloseIcon color="red" />}
          loading={isDeclineLoading}
          ghost
          color="red"
        >
          Отклонить
        </AntButtonGreen>,

        <AntButtonGreen
          type="primary"
          key="ok"
          icon={<CheckIcon />}
          loading={isApproveLoading}
          onClick={() => {
            approvePhoto({ personId })
              .unwrap()
              .then(() => {
                notificationsRefetch()
                  .unwrap()
                  .then(() => {
                    dispatch(openApprovePhotoModal(false));
                    dispatch(setPersonIdThatNeedApprovePhoto(null));

                    // Проверяем если модалку вызвали со страницы редактирования пользователя
                    if (isApproveFromUserPage) {
                      dispatch(setIsNeedRefetchUserPage(true));
                    }

                    messageApi.open(
                      createConfig({
                        type: "success",
                        content: "Фото успешно подтверждено!",
                      })
                    );
                  });
              })
              .catch(() => {
                messageApi.open(
                  createConfig({
                    type: "error",
                    content: "Возникла ошибка при подтверждении фото",
                  })
                );
              });
          }}
        >
          Подтвердить
        </AntButtonGreen>,
      ]}
    >
      <div className={style.modalContent}>
        <p className={style.text}>
          Подтвердите, что фотография пользователя соответствует его
          персональным данным. Убедитесь, что на изображении хорошо видно его
          лицо и отсутствуют посторонние лица. Это способствует эффективному и
          качественному прохождению медицинского осмотра пользователя.
        </p>

        <AntImage width={300} src={personPhotoUrl.url} />

        <p className={style.personInfo}>
          {getFioWithDots(
            person.lastName || "",
            person.firstName || "",
            person.patronymic || "",
            40
          )}
        </p>
        <p className={style.personInfo}>
          {dayjs(person.birthDate).format("D MMMM YYYY")} г.
        </p>
        <p className={style.personInfo}>{normalizePhone(person.phone)}</p>
      </div>
    </AntModal>
  );
};

export default ApprovePhotoModal;
