import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import loginReducer from "../storeSlices/login";
import appSettingsReducer from "../storeSlices/appSettings";
import notificationReducer from "../storeSlices/notification";
import approvePhotoDataReducer from "../storeSlices/approvePhotoData";
import { emptySplitApi } from "./emptyApi";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    appSettings: appSettingsReducer,
    notification: notificationReducer,
    approvePhotoData: approvePhotoDataReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(emptySplitApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
